<template>
  <v-container fluid>
    <v-data-table
      class="pt-3"
      flat
      :loading="loading ? '#144881' : null"
      :headers="headers"
      :search="searchData"
      :items="Students"
      :items-per-page="5"
      sort-by=""
      show-select
      item-key="_id"
      v-model="selectedStudents"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des <span class="font-weight-bold">étudiants</span> de l'ECUE : {{ Ecue.name }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <!-- <template class="">
              <v-btn
                color="green"
                dark
                class="mb-2 mr-8"
                @click="printBadge('*')"
                v-bind="attrs"
                outlined
                pill
                v-on="on"
                rounded
              >
                <v-icon left> mdi-printer-pos </v-icon>
                Imprimer badge
              </v-btn>
            </template> -->
        </v-toolbar>
        <v-container fluid>
          <v-expansion-panels flat style="border: 1px solid #144881">
            <v-expansion-panel class="elevation-0 pa-0">
              <v-expansion-panel-header>
                Tri / Recherche ...
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      color="#144881"
                      label="Recherche "
                      class="pt-5 pa-2"
                      filled
                      prepend-inner-icon="mdi-magnify"
                      dense
                      v-model="searchData"
                      outlined
                      flat
                      background-color="grey lighten-4"
                      rounded
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      class="pt-5 pa-2"
                      label="Niveau"
                      prepend-inner-icon="mdi-magnify"
                      dense
                      background-color="grey lighten-4"
                      rounded
                      v-model="searchLevel"
                      :items="[100, 200, 300, 400, 500]"
                      outlined
                    ></v-select>
                  </v-col> 
                  <v-col cols="12" md="4">
                    <v-select
                      class="pt-5 pa-2"
                      label="Mention"
                      dense
                      prepend-inner-icon="mdi-graph"
                      background-color="grey lighten-4"
                      rounded
                      v-model="searchDepartment"
                      :items="Departments.map((item) => item.name)"
                      outlined
                    ></v-select>
                  </v-col>
                  <!-- 
                  <v-col cols="12" md="2">
                    <v-select
                      class="pt-5 pa-2"
                      label="Pourcentage de paiement de la contribution"
                      dense
                      prepend-inner-icon="mdi-cash-100"
                      background-color="grey lighten-4"
                      rounded
                      v-model="searchSchoolFeesPercentage"
                      :items="[0, 25, 50, 75, 100]"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select
                      class="pt-5 pa-2"
                      label="Badge"
                      dense
                      prepend-inner-icon="mdi-badge-account-horizontal-outline"
                      background-color="grey lighten-4"
                      rounded
                      v-model="badgeSort"
                      :items="['Non payé', 'Payé', 'Imprimé', 'Non imprimé']"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" class="">
                    <v-menu :close-on-content-click="false" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="primary white--text my-0"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          :loading="loading"
                        >
                          Imprimer le relevé de note
                        </v-btn>
                      </template>

                      <v-card class="mx-auto px-3 py-3" max-width="500" tile>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6"
                              >Impression du relevé de note</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-select
                          :items="semestersName"
                          label="Choisir la semestre"
                          v-model="selectedSemester"
                          outlined
                        ></v-select>

                        <v-btn
                          @click="printAcademicTranscript"
                          class="rounded primary"
                          :loading="loading"
                        >
                          Imprimer la selection
                        </v-btn>
                      </v-card>
                    </v-menu>
                  </v-col> -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu elevation="0" right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mr-md-0">
              <v-icon color="success" size="30">mdi-forwardburger</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="
                    `/ue/ecue/${$route.params.id}/student/` + item._id + '/mark'
                  "
                >
                  <v-btn class="ml-3" icon>
                    <v-icon size="20" v-bind="attrs" v-on="on" color="green"
                      >mdi-arrow-right</v-icon
                    >
                  </v-btn>
                </router-link>
              </template>
              <span>Déveloper</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar class="my-3">
          <v-img :src="item.avatar" />
        </v-avatar>
      </template>
    </v-data-table>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        :fields="fields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import { functions } from "@/utils/constant";
import {
  addFaculty,
  updateFaculty,
  deleteFaculty,
  printBadge,
  printAcademicTranscript,
} from "@/api/user";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";
export default {
  name: "Students",
  components: {
    EditorX,
    DeleteDialog,
  },

  data: () => ({
    selectedSemester: "",
    semestersName: [],
    selectedStudents: [],
    callBack: "",
    searchData: "",
    searchDepartment: "",
    searchSchoolFeesPercentage: "",
    badgeSort: "",
    searchLevel: "",
    dialog: false,
    loading: false,
    headers: [
    {
        text: "Actions",
        width: "40px",
        align: "left",
        value: "actions",
        sortable: false,
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "avatar",
      },
      {
        text: "Numéro maticule",
        align: "start",
        sortable: true,
        value: "registrationNumber",
      },
      {
        text: "Nom",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "Prénom",
        align: "start",
        sortable: true,
        value: "firstName",
      },

      {
        text: "Moyenne interrogation",
        sortable: true,
        value: "controlAverage",
      },
      {
        text: "Note Partiel",
        sortable: true,
        value: "examScore",
      },
      
      {
        text: "Dégré",
        sortable: false,
        value: "translatedLevel",
      },
      {
        text: "Mention",
        sortable: false,
        value: "departmentName",
      },
      {
        text: "Option",
        sortable: false,
        value: "optionName",
      },
      
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "Students",
      "Schools",
      "UserProfile",
      "Departments",
      "Options",
      "Semesters",
      "Ecue",
    ]),
    fields() {
      return [
        {
          name: "name",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "acronym",
          label: "Acronyme",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "school",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value:
            this.UserProfile.school && this.UserProfile.school._id
              ? this.UserProfile.school._id
              : this.UserProfile.school,
        },
      ];
    },
  },

  watch: {
    searchDepartment() {
      this.init();
    },
    searchSchoolFeesPercentage() {
      this.init();
    },
    badgeSort() {
      this.init();
    },
    searchLevel() {
      this.init();
    },
    Semesters() {
      this.semestersName = [];
      for (let item of this.Semesters) {
        if (!this.semestersName.includes(item.name)) {
          this.semestersName.push(item.name);
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "getStudents",
      "getSchools",
      "getDepartments",
      "getEcue",
      "getSemesters",
    ]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        let department = "";
        if (this.searchDepartment) {
          department = functions.getItemId(
            this.Departments,
            this.searchDepartment
          );
        }
        let currentLevel = "";
        if (this.searchLevel) {
          currentLevel = this.searchLevel;
        }
        let schoolFeesPercentage = "";
        if (this.searchSchoolFeesPercentage) {
          schoolFeesPercentage = this.searchSchoolFeesPercentage;
        }
        let badgeSort = "";
        if (this.badgeSort) {
          badgeSort = this.badgeSort;
        }
        await this.getStudents({
          department,
          currentLevel,
          schoolFeesPercentage,
          badgeSort,
          ecue: this.$route.params.id,
        });
        // await this.getDepartments({ faculty: "" });
        // await this.getOptions({ department: "" });
        await this.getEcue({ id: this.$route.params.id });


        await this.getSemesters({
          schoolYear: this.UserProfile.school.currentYear._id,
        });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les années")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addFaculty;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateFaculty;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteFaculty;
    },
    async printBadge(item = "*") {
      this.loading = true;
      try {
        let paths;
        if (item == "*") {
          paths = await printBadge({
            schema: {
              students: this.selectedStudents.map((it) => it._id),
            },
          });
        } else {
          paths = await printBadge({
            schema: {
              students: [item._id],
            },
          });
        }

        for (let url of paths) {
          await functions.downloadFromLink(url);
          await functions.sleep(2000);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.init();
    },
    async printAcademicTranscript() {
      this.loading = true;
      try {
        let paths = [];
        const students = this.selectedStudents.map((it) => it._id);
        for (let student of students) {
          const resp = await printAcademicTranscript({
            schema: {
              student: student,
              semester: functions.getItemId(
                this.Semesters,
                this.selectedSemester
              ),
            },
          });
          console.log(resp);
          paths.push(resp);
        }
        // alert(JSON.stringify(paths));
        for (let url of paths) {
          await functions.downloadFromLink(url);
          await functions.sleep(2000);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.init();
    },
  },
};
</script>
  
  <style >
</style>
  